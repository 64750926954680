import React from 'react';
import { Typography } from '@material-ui/core';
import { Amount } from 'components';
import useStyles from '../style';

const SuperCouple = (props) => {
  const classes = useStyles();

  return (
    <>
      <Amount number={props.cost} />
      <div className={classes.subTotal}>
        {props.cost1} + {props.cost2}
      </div>
      {props.haveMonthlyPlans && (
        <Typography color="primary" variant="body2">
          {props.costMonthly}/month
        </Typography>
      )}
    </>
  );
};

export default SuperCouple;
