import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  uiWrap: {
    position: 'relative',
    overflow: 'hidden'
  },
  labelA: {
    display: 'inline-block',
    marginRight: '10px',

    '&.active': {
      fontWeight: 'bold'
    }
  },
  labelB: {
    display: 'inline-block',
    marginLeft: '10px;',

    '&.active': {
      fontWeight: 'bold'
    }
  },
  ui: {
    width: '74px',
    height: '32px',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    display: 'inline-block',
    borderRadius: '16px',
    cursor: 'pointer',

    '&:before': {
      position: 'absolute',
      content: '""',
      backgroundColor: '#fff',
      width: '28px',
      height: '28px',
      borderRadius: '16px',
      top: '2px',
      transition: 'all .3s ease'
    },

    '&.activeA:before': {
      left: '2px'
    },

    '&.activeB:before': {
      left: '42px'
    }
  }
}));

const Switch2 = ({ value, labelA, labelB, onChange }) => {
  const [activeLabel, setActiveLabel] = useState(value);
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <Typography
        component="span"
        className={clsx(classes.labelA, activeLabel === 'A' && 'active')}>
        {labelA || '-'}
      </Typography>

      <div
        className="uiWrap"
        onClick={() => {
          let newLabelState = activeLabel === 'A' ? 'B' : 'A';
          setActiveLabel(newLabelState);

          if (onChange) {
            onChange(newLabelState);
          }
        }}>
        <span>
          <div className={clsx(classes.ui, `active${activeLabel}`)}></div>
        </span>
      </div>

      <Typography
        component="span"
        className={clsx(classes.labelB, activeLabel === 'B' && 'active')}>
        {labelB || '-'}
      </Typography>
    </div>
  );
};

export default Switch2;
