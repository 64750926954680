import { colors } from '@material-ui/core';

export default {
  '.d-flex': {
    display: 'flex',
    flexWrap: 'wrap'
  },
  '.d-inline-flex': {
    display: 'inline-flex'
  },
  '.justify-content-center': {
    justifyContent: 'center'
  },
  '.align-items-center': {
    alignItems: 'center'
  },
  '.frm-grp': {
    marginBottom: '1.5rem'
  },
  '.fld-row': {
    position: 'relative'
  },
  '.fld-no': {
    display: 'none',
    position: 'absolute',
    top: 15,
    left: '-30px',
    backgroundColor: colors.teal[100],
    borderRadius: '50%',
    width: 25,
    height: 25,
    lineHeight: '25px',
    textAlign: 'center',
    '&.active': {
      display: 'block'
    },
    '&.fld-no-sm': {
      top: 7
    }
  },
  '.fld-no-2': {
    backgroundColor: colors.yellow[100]
  },
  '.fld-min-150': {
    minWidth: 150
  },
  '.text-icon': {
    display: 'inline-flex',
    alignItems: 'center'
  },
  '.pop-over': {
    padding: 20,
    maxWidth: 300
  },
  '.mr-1': {
    marginRight: '8px'
  },
  '.mb-1': {
    marginBottom: '8px'
  },
  '.mb-3': {
    marginBottom: '24px'
  },
  '.mb-5': {
    marginBottom: '40px'
  },
  '.px-10': {
    paddingTop: '120px',
    paddingBottom: '120px'
  },
  '.pl-0': {
    paddingLeft: '0'
  },
  '.list-unstyled': {
    listStyle: 'none'
  }
};
