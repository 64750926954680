import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppLifeCriticalIllness from './AppLifeCriticalInsurance';
import AppLifeCriticalIllnessQuotes from './AppLifeCriticalInsuranceQuotes';
import * as serviceWorker from './serviceWorker';

// Default App
if (document.getElementById('app')) {
  ReactDOM.render(<App />, document.getElementById('app'));
}

// Life and CriticalIllness Insurance
const elFormLifeCriticalIllness = document.getElementById(
  'frmLifeCriticalIllness'
);

if (elFormLifeCriticalIllness) {
  ReactDOM.render(
    <AppLifeCriticalIllness {...{ ...elFormLifeCriticalIllness.dataset }} />,
    elFormLifeCriticalIllness
  );
}

const elResultLifeCriticalIllness = document.getElementById(
  'resultLifeCriticalIllnessQuotes'
);

if (elResultLifeCriticalIllness) {
  ReactDOM.render(
    <AppLifeCriticalIllnessQuotes
      {...{ ...elResultLifeCriticalIllness.dataset }}
    />,
    elResultLifeCriticalIllness
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
