import React from 'react';
import { Amount } from 'components';
import useStyles from '../style';

const VisitorCouple = (props) => {
  const classes = useStyles();

  return (
    <>
      <Amount number={props.cost} classes={classes} />
      <div className={classes.subTotal}>
        {props.cost1} + {props.cost2}
      </div>
    </>
  );
};

export default VisitorCouple;
