import React, { useEffect, useState } from 'react';
import { getLifeCIQuote } from '../../api/lifeCiApi';
import {
  CircularProgress,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { schema } from '../LifeCriticalInsurance/schema';
import { parse, format } from 'date-fns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as clientApi from '../../api/clientApi';
import { toast } from 'react-toastify';
import { PhoneFormat, SendQuote } from '../index';
import rateApi from '../../api/rateApi';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useLocation } from 'react-router-dom'; // this will not work because we are not using react router for this component
import queryString from 'query-string';
import {
  criticalIllnessItemsList,
  criticalIllnessItemsOptions,
  faceAmountOptions,
  faceAmountsOptionsPopular,
  lifeInsuranceGroupItemList,
  lifeInsuranceGroupItemOptions,
  optiTermItems,
  paymentModeOptions,
  riskOptionsCI,
  riskOptionsLife
} from '../LifeCriticalInsurance/config';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  // below will not work without react router dom;
  //const { search } = useLocation();

  const search = window.location.search;

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LifeCriticalInsuranceQutoe = ({ formPath, orderPath, insuranceType }) => {
  let query = useQuery();
  const [loading, setLoading] = useState('idle');
  const [client, setClient] = useState(null);
  const [quotes, setQuotes] = useState(null);

  const handleSendQuote = (values) => {
    return rateApi.sendQuote({
      ...values,
      url: window.location.href
    });
  };

  useEffect(() => {
    if (loading === 'idle') {
      schema
        .validate({
          ...Object.fromEntries(query),
          dob: parse(query.get('dob'), 'MMddyyyy', new Date())
        })
        .then((values) => {
          //console.log('querystring', values);
          const fValues = {
            ...values,
            dob: format(values.dob, 'MMddyyyy')
          };

          let query = '';
          Object.keys(fValues).forEach((k) => {
            query = `${query} -${k}${fValues[k]}`;
          });

          setLoading('loading');

          getLifeCIQuote(query)
            .then((results) => {
              //console.log(results);
              setLoading('success');
              setQuotes(results);
            })
            .catch((err) => {
              setLoading('failed');
              console.error(err);
              toast.error('API failure');
            });
        })
        .catch((err) => {
          console.error(err);
          toast.error('Invalid input!');
        });
    }
  }, [
    setQuotes,
    query.get('fa'),
    query.get('pm'),
    query.get('r'),
    query.get('pg'),
    query.get('pi')
  ]);

  // load client
  useEffect(() => {
    if (!client) {
      if (!client) {
        clientApi
          .getClient()
          .then((client) => {
            setClient(client);
          })
          .catch((err) => {
            console.log(err);
            // Non critical error: it not necessary to show the error to the visitors;
            /*toast.error(
              'Network error: could not retrieve client, please try again or contact support!'
            );*/
          });
      }
    }
  });

  /*useEffect(() => {
    console.log('product type changed', query.get('productType'));
  }, [query.get('productType')]);*/

  const handleProductTypeChange = (e) => {
    // this is only called from termLife & wholeLife
    // CI doesn't have this field;
    const [pg, pi] = e.target.value.split('-');

    if (insuranceType === 'lifeInsurance') {
      // if pg:1 (OptiLife) is selected,
      if (pg === '1') {
        query.set('productType', 1);
        query.set('pg', pg);
        query.set('pi', '0'); // set pi:0 (5 year term)
      } else {
        query.set('productType', e.target.value);
        query.set('pg', pg);
        query.set('pi', pi);
      }

      setQuotes(null);
      setLoading('idle');
    }
  };

  const handleChange = (field, value) => {
    query.set(field, value);
    setQuotes(null);
    setLoading('idle');
    // update the history;
    window.history.pushState(
      null,
      '',
      window.location.pathname +
        '?' +
        queryString.stringify(Object.fromEntries(query))
    );
  };

  return (
    <>
      <label htmlFor="icon-button-file lci-quotes-back-btn">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => {
            window.location.href = `${formPath}${window.location.search}`;
            //console.log('get back to ', formPath, window.location.search);
          }}>
          <ArrowBackIcon />
        </IconButton>
      </label>
      {/* backButton */}

      <div className="life-meta">
        <div className="life-meta-col life-meta-age">
          <div className="life-meta-label life-meta-label-age">Age</div>
          <div className="life-meta-value life-meta-value-age">
            <strong>{query.get('age')} years</strong>
          </div>
        </div>

        <div className="life-meta-col life-meta-gender">
          <div className="life-meta-label life-meta-label-gender">Gender</div>
          <div className="life-meta-value life-meta-value-gender">
            <strong>
              {query.get('g') && query.get('g') === '1' ? 'Male' : 'Female'}
            </strong>
          </div>
        </div>

        <div className="life-meta-col life-meta-tobacco">
          <div className="life-meta-label life-meta-label-tobacco">
            Tobacco use?
          </div>
          <div className="life-meta-value life-meta-value-tobacco">
            <strong>
              {query.get('tobacco') && query.get('tobacco') === '0'
                ? 'No'
                : 'Yes'}
            </strong>
          </div>
        </div>
      </div>

      <div className="lci-quotes-filter d-flex justify-content-center">
        <div className="frm-grp mr-1 frm-grp-fa">
          <TextField
            select
            label="Coverage Amount"
            onChange={(e) => {
              handleChange('fa', e.target.value);
            }}
            SelectProps={{
              native: true
            }}
            variant="outlined"
            value={query.get('fa')}>
            {[
              { value: '0', title: 'Face Amount' },
              ...(faceAmountsOptionsPopular[insuranceType] || []),
              ...faceAmountOptions
            ].map((item, idx) => (
              <option
                key={`faceAmount-${item.value}-${idx}`}
                value={item.value}>
                {item.title}
              </option>
            ))}
          </TextField>
        </div>
        {/* faceAmount */}

        <div className="frm-grp mr-1 frm-grp-pm">
          <TextField
            select
            label="Premium Mode"
            onChange={(e) => {
              handleChange('pm', e.target.value);
            }}
            SelectProps={{
              native: true
            }}
            variant="outlined"
            value={query.get('pm')}>
            {Object.keys(paymentModeOptions).map((k) => (
              <option key={k} value={k}>
                {paymentModeOptions[k]}
              </option>
            ))}
          </TextField>
        </div>
        {/* paymentMode */}

        {insuranceType === 'lifeInsurance' && (
          <>
            <div className="frm-grp mr-1 frm-grp-productType">
              <TextField
                select
                label="Plan Type"
                onChange={handleProductTypeChange}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                value={query.get('productType')}>
                {lifeInsuranceGroupItemList.map((k) => (
                  <option key={k} value={k}>
                    {lifeInsuranceGroupItemOptions[k]}
                  </option>
                ))}
              </TextField>
            </div>

            <>
              {query.get('productType') === '1' && (
                <div className="frm-grp mr-1 frm-grp-pi">
                  <TextField
                    select
                    label="OptiTerm Plan"
                    onChange={(e) => {
                      handleChange('pi', e.target.value);
                    }}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    value={query.get('pi')}>
                    {Object.keys(optiTermItems).map((k) => (
                      <option key={k} value={k}>
                        {optiTermItems[k]}
                      </option>
                    ))}
                  </TextField>
                </div>
              )}
            </>
            {/* OptiTerm Items */}
          </>
        )}

        {insuranceType === 'criticalIllness' && (
          <div className="frm-grp mr-1 frm-grp-ci">
            <TextField
              select
              label="CI Plan"
              onChange={(e) => {
                handleChange('pi', e.target.value);
              }}
              SelectProps={{
                native: true
              }}
              variant="outlined"
              value={query.get('pi')}>
              {criticalIllnessItemsList.map((k) => (
                <option key={k} value={k}>
                  {criticalIllnessItemsOptions[k]}
                </option>
              ))}
            </TextField>
          </div>
        )}

        <div className="frm-grp mr-1 frm-grp-r">
          <TextField
            select
            label="Health Class"
            onChange={(e) => {
              handleChange('r', e.target.value);
            }}
            SelectProps={{
              native: true
            }}
            variant="outlined"
            value={query.get('r')}>
            {Object.keys(
              insuranceType === 'criticalIllness'
                ? riskOptionsCI
                : riskOptionsLife
            ).map((k) => (
              <option key={k} value={k}>
                {insuranceType === 'criticalIllness'
                  ? riskOptionsCI[k]
                  : riskOptionsLife[k]}
              </option>
            ))}
          </TextField>
        </div>
        {/* risk */}
      </div>
      {/* filter*/}

      {loading === 'loading' && (
        <div className="px-10 d-flex justify-center">
          <CircularProgress />
        </div>
      )}

      {quotes &&
        quotes.results &&
        quotes.results.record &&
        quotes.results.record.recordSet && (
          <>
            <>
              {!quotes.results.record.recordSet.length ? (
                <Typography variant="body1">
                  0 results match your query, please try again.
                </Typography>
              ) : (
                <>
                  <SendQuote onSendQuote={handleSendQuote} client={client} />
                  {/* textEmail */}

                  <div className="lci-quotes lci-quotes-headings">
                    <div className="lci-quotes-clm lci-quotes-clm-brand">
                      <span>Company</span>
                    </div>
                    <div className="lci-quotes-clm lci-quotes-clm-quote">
                      <span>Quote</span>
                    </div>
                    <div className="lci-quotes-clm lci-quotes-clm-details">
                      <span>Plan</span>
                    </div>
                    <div className="lci-quotes-clm lci-quotes-clm-cta">
                      <span>
                        Next? <ArrowDownwardIcon />
                      </span>
                    </div>
                  </div>
                  {/* recordTopHeadings */}
                </>
              )}
            </>

            {quotes.results.record.recordSet.map((quote) => (
              <div
                className="lci-quotes lci-quotes-records"
                key={quote.recordSetHeading.rankItem}>
                <div className="lci-quotes-clm lci-quotes-clm-brand">
                  <div className="lci-quotes-records-heading lci-quotes-records-heading-brand">
                    Company
                  </div>
                  <div className="lci-quotes-brand-logo">
                    <img
                      src={`${process.env.REACT_APP_STORAGE_URL}/images/partners-lci/${quote.recordSetHeading.companyNameCode}.png`}
                      alt={quote.company}
                    />
                  </div>
                  <p className="lci-quotes-brand-company">{quote.company}</p>
                </div>

                <div className="lci-quotes-clm lci-quotes-clm-quote">
                  <div className="lci-quotes-records-heading lci-quotes-records-heading-quote">
                    Quote
                  </div>
                  <p className="lci-quotes-quote-premium">
                    {quote.premiumText}
                  </p>
                  <p className="lci-quotes-quote-payment-mode">
                    {quote.paymentMode}
                  </p>
                </div>

                <div className="lci-quotes-clm lci-quotes-clm-details">
                  <div className="lci-quotes-records-heading lci-quotes-records-heading-details">
                    Plan
                  </div>
                  <ul className="lci-quotes-details-meta">
                    <li className="lci-quotes-details-meta-product-name">
                      {quote.product.replace('[NT]', '')}
                    </li>
                    <li className="lci-quotes-details-meta-product-class">
                      {quote.pClass}
                    </li>
                    <li className="lci-quotes-details-meta-risk">
                      {quote.risk}
                    </li>
                  </ul>
                </div>

                <div className="lci-quotes-clm lci-quotes-clm-cta">
                  <div className="lci-quotes-records-heading lci-quotes-records-heading-cta">
                    Next
                  </div>
                  <div className="lci-quotes-cta lci-quote-cta-apply">
                    <a
                      href={`${
                        orderPath ? orderPath : '/order-lci/'
                      }?uri=${encodeURIComponent(window.location)}&company=${
                        quote.company
                      }&quote=${quote.premium}&plan=${quote.product}`}
                      className="btn btn-primary">
                      Apply Now
                    </a>
                  </div>
                  {client && client.cellNo && (
                    <div className="lci-quotes-cta lci-quote-cta-call">
                      <a href={`tel:${client.cellNo}`}>
                        <PhoneFormat number={client.cellNo} />
                      </a>
                    </div>
                  )}
                </div>

                {/*<pre>{JSON.stringify(quote, null, 2)}</pre>*/}
              </div>
            ))}
          </>
        )}
    </>
  );
};

export default LifeCriticalInsuranceQutoe;
