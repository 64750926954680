import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import quoteSchemas from 'schemas/quotes';
import rateApi from 'api/rateApi';
import camelCase from 'lodash/camelCase';
import { dbToDate, toQueryString, datesToDays } from 'common/Helper';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CompareList, Comparison, MetaInfo, Item } from './components';
import { SendQuote } from 'components';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import * as clientApi from '../../api/clientApi';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  loading: {
    textAlign: 'center'
  }
}));

const Quotes = ({ history, location, insuranceType }) => {
  // props.insuranceType is passed from the App (pages)
  // props.coverageType is passed from the forms (query parameters)
  const classes = useStyles();
  const [query, setQuery] = useState();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [schema, setSchema] = useState();
  const [apiEndPoint, setApiEndPoint] = useState();
  const [backUrl, setBackUrl] = useState();
  const [rates, setRates] = useState();
  const [ratesFiltered, setRatesFiltered] = useState();
  const [compareList, setCompareList] = useState([]);
  const [showComparison, setShowComparison] = useState(false);

  useEffect(() => {
    const query = queryString.parse(location.search);
    query.deductible = query.deductible ? parseInt(query.deductible) : 0;
    query.havePreConditions1 = query.havePreConditions1 === 'true';
    query.havePreConditions2 = query.havePreConditions2 === 'true';
    query.days1 = datesToDays(
      dbToDate(query.endDate1),
      dbToDate(query.startDate1)
    );
    query.days2 = datesToDays(
      dbToDate(query.endDate2),
      dbToDate(query.startDate2)
    );

    setQuery(query);

    // Set Client
    if (!client) {
      clientApi
        .getClient()
        .then((client) => {
          setClient(client);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            'Network error: could not retrieve client, please try again or contact support!'
          );
        });
    }
  }, []);

  useEffect(() => {
    if (query) {
      // no need to validate input for apiEndpoint;
      // because we will only call endpoint if the schema is valid;
      //student insurance doesn't have coverageType;
      if (insuranceType === 'student') {
        setApiEndPoint(camelCase(`get_${insuranceType}_rates`));
      } else {
        setApiEndPoint(
          camelCase(`get_${insuranceType}_${query.coverageType}_rates`)
        );
      }

      // create a schema for validating the input
      switch (insuranceType) {
        case 'super':
          if (
            query.coverageType === 'single' ||
            query.coverageType === 'couple'
          ) {
            setSchema(
              camelCase(`${insuranceType}_${query.coverageType}_schema`)
            );
            setBackUrl(
              `/super-visa-insurance/${query.coverageType}?${toQueryString(
                query
              )}`
            );
          }
          break;
        case 'visitor':
          if (
            query.coverageType === 'single' ||
            query.coverageType === 'couple' ||
            query.coverageType === 'family'
          ) {
            setSchema(
              camelCase(`${insuranceType}_${query.coverageType}_schema`)
            );
            setBackUrl(
              `/visitors-insurance/${query.coverageType}?${toQueryString(
                query
              )}`
            );
          }
          break;
        case 'student':
          setSchema(camelCase(`${insuranceType}_schema`));
          setBackUrl(`/student-insurance?${toQueryString(query)}`);
          break;
        default:
          console.log('error');
      }
    }
  }, [query]);

  useEffect(() => {
    loadResult();
  }, [schema]);

  // HANDLERS
  const loadResult = () => {
    setLoading(true);
    if (schema) {
      quoteSchemas[schema]
        .validate({
          ...query,
          startDate1: dbToDate(query.startDate1),
          endDate1: dbToDate(query.endDate1),
          startDate2: dbToDate(query.startDate1),
          endDate2: dbToDate(query.endDate1),
          dob1: query.dob1 ? dbToDate(query.dob1) : '',
          dob2: query.dob2 ? dbToDate(query.dob2) : ''
        })
        .then(() => {
          rateApi[apiEndPoint](query)
            .then((rates) => {
              setRates(rates);
              setLoading(false);
              if (insuranceType === 'student') {
                setRatesFiltered(shortRatesStudent(rates));
              } else {
                // super, visitor
                setRatesFiltered(
                  query.plan
                    ? shortRates(rates)
                    : filterShortRates(rates, query.deductible)
                );
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error(
                'Network error: please try again or contact support!'
              );
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          toast.error('Validation error: please try again or contact support!');
        });
    }
  };

  const handleQueryChange = (key, value) => {
    query[key] = value;
    history.push(`${location.pathname}?${toQueryString(query)}`);
    loadResult();
  };

  const handleDeductibleChange = (deductible) => {
    query.deductible = deductible;
    history.push(`${location.pathname}?${toQueryString(query)}`);
    setRatesFiltered(
      query.qPlan ? shortRates(rates) : filterShortRates(rates, deductible)
    );
  };

  const handleBackClick = () => {
    history.push(backUrl);
  };

  const handleAddToCompare = (plan, isAdd) => {
    if (isAdd) {
      setCompareList([...compareList, plan]);
    } else {
      setCompareList(compareList.filter((item) => item._id !== plan._id));
    }
  };

  const handleCompareBackClick = () => {
    setCompareList([]);
    setShowComparison(false);
  };

  const handleRateSelect = (rate) => {
    window.location.href = `/plan?${toQueryString({
      ...query,
      plan: rate.plan._id,
      planName: rate.plan.name,
      deductible: rate.deductible,
      insuranceType: insuranceType
    })}`;
  };

  const handleSendQuote = (values) => {
    return rateApi.sendQuote({
      ...values,
      url: window.location.origin + location.pathname + location.search
    });
  };

  // HELPERS
  const filterShortRates = (rates, deductible = 0) => {
    return rates
      .filter((record) => {
        return record.deductible === deductible;
      })
      .sort((a, b) => (parseFloat(a.cost) > parseFloat(b.cost) ? 1 : -1));
  };

  const shortRatesStudent = (rates) => {
    return rates.sort((a, b) =>
      parseFloat(a.cost) > parseFloat(b.cost) ? 1 : -1
    );
  };

  const shortRates = (rates) => {
    return rates.sort((a, b) => (a.deductible > b.deductible ? 1 : -1));
  };

  return (
    <>
      {showComparison ? (
        <Comparison
          compareList={compareList}
          coverageType={query.coverageType}
          insuranceType={insuranceType}
          onCompareBackClick={handleCompareBackClick}
          onRateSelect={handleRateSelect}
        />
      ) : (
        <>
          <MetaInfo
            {...query}
            insuranceType={insuranceType}
            onBackClick={handleBackClick}
            onDeductibleChange={handleDeductibleChange}
            onQueryChange={handleQueryChange}
          />
          <SendQuote onSendQuote={handleSendQuote} client={client} />
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : (
            <>
              {ratesFiltered && ratesFiltered.length ? (
                <>
                  <CompareList
                    items={compareList}
                    onCompareClick={setShowComparison}
                  />
                  <div className={classes.root}>
                    <Grid spacing={3} container className={clsx('quotes')}>
                      {ratesFiltered.map((record) => (
                        <Item
                          insuranceType={insuranceType}
                          key={record.plan._id + record.deductible}
                          onAddToCompare={handleAddToCompare}
                          onRateSelect={handleRateSelect}
                          query={query}
                          record={record}
                        />
                      ))}
                    </Grid>
                  </div>
                </>
              ) : (
                <Typography
                  align="center"
                  className={clsx('quote-result', 'quote-result-0')}
                  variant="body1">
                  Your search returned <strong>0</strong> results. Please try
                  again with a different search criteria.
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Quotes.propTypes = {
  history: PropTypes.object,
  insuranceType: PropTypes.string,
  location: PropTypes.object
};

export default Quotes;
