import React from 'react';
import { Typography } from '@material-ui/core';
import { Amount } from 'components';
import useStyles from '../style';

const SuperSingle = (props) => {
  const classes = useStyles();

  return (
    <>
      <Amount number={props.cost} classes={classes} />
      {props.haveMonthlyPlans && (
        <Typography color="primary" variant="body2">
          {props.costMonthly}
          /month
        </Typography>
      )}
    </>
  );
};

export default SuperSingle;
