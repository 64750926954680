export const companiesList = [
  'ASSUMPTI',
  'BMOINSUR',
  'CANADALI',
  'CPPUNDER',
  'DESJARDI',
  'EMPIRELI',
  'EQUITABL',
  'FAITHLIF',
  'FORESTER',
  'HUMANIAA',
  'IAFINANC',
  'IVARI',
  'LACAPITA',
  'MANULIFE',
  'ORANGEBE',
  'RBCINSUR',
  'SSQINSUR',
  'SUNLIFE',
  'THEEDGEB',
  'UVASSURA',
  'UVINSURA'
];
// 1 => optiterm is broken in Canadian LIC, need another dropdown list to filter optiterm options
export const lifeInsuranceGroupItemOptions = {
  '0-2': '10 year Term',
  '0-3': '15 year Term',
  '0-4': '20 year Term',
  '0-5': '25 Year Term',
  '0-6': '30 Year Term',
  '0-7': '35 Year Term',
  '0-8': '40 Year Term',
  '0-9': 'Level term to 65',
  '0-10': 'Level term to 70',
  '0-11': 'Level term to 75',
  '1': 'OptiTerm',
  // term 100 (2)
  '2-0': 'Traditional Term 100',
  '2-1': 'T100 20-Pay',
  // whole life (3)
  '3-0': 'Whole Life / Life Pay',
  '3-1': 'Whole Life / Pay to 65',
  '3-2': 'Whole Life / 25 Pay',
  '3-3': 'Whole Life / 20 Pay',
  '3-4': 'Whole Life / 15 Pay',
  '3-5': 'Whole Life / Quick Pay',
  '3-9': 'Unique Whole Life',
  // universal life (4),
  '4-0': 'UL / T100 Min. Premium',
  '4-1': 'UL / Pay to 65 Min. Premium',
  '4-2': 'UL / 20 Pay Min. Premium',
  '4-3': 'UL / 15 Pay Min. Premium',
  '4-4': 'UL / 10 Pay Min. Premium',
  '4-5': 'UL / Term 10 Pay Min. Premium',
  '4-6': 'UL / Term 20 Pay Min. Premium',
  '4-7': 'UL / Term 30 Pay Min. Premium'
};
export const lifeInsuranceGroupItemList = [
  '0-2',
  '0-3',
  '0-4',
  '0-5',
  '0-6',
  '0-7',
  '0-8',
  '0-9',
  '0-10',
  '1',
  //term 100
  '2-0',
  '2-1',
  //whole life
  '3-0',
  '3-1',
  '3-2',
  '3-3',
  '3-4',
  '3-5',
  '3-9',
  // universal life
  '4-0',
  '4-1',
  '4-2',
  '4-3',
  '4-4',
  '4-5',
  '4-6',
  '4-7'
];

export const optiTermItems = {
  '0': '5 year Term',
  '1': '6 year Term',
  '2': '7 Year Term',
  '3': '8 Year Term',
  '4': '9 Year Term',
  '5': '10 year Term',
  '6': '11 year Term',
  '7': '12 year Term',
  '8': '13 Year Term',
  '9': '14 Year Term',
  '10': '15 Year Term',
  '11': '16 Year Term',
  '12': '17 Year Term',
  '13': '18 Year Term',
  '14': '19 Year Term',
  '15': '20 year Term',
  '16': '21 year Term',
  '17': '22 year Term',
  '18': '23 Year Term',
  '19': '24 Year Term',
  '20': '25 Year Term',
  '21': '26 Year Term',
  '22': '27 Year Term',
  '23': '28 Year Term',
  '24': '29 Year Term',
  '25': '30 year Term',
  '26': '31 year Term',
  '27': '32 year Term',
  '28': '33 Year Term',
  '29': '34 Year Term',
  '30': '35 Year Term',
  '31': '36 Year Term',
  '32': '37 Year Term',
  '33': '38 Year Term',
  '34': '39 Year Term',
  '35': '40 year Term',
  '36': '41 year Term',
  '37': '42 year Term',
  '38': '43 Year Term',
  '39': '44 Year Term',
  '40': '45 Year Term',
  '41': '46 Year Term',
  '42': '47 Year Term',
  '43': '48 Year Term',
  '44': '49 Year Term',
  '45': '50 year Term'
};

export const criticalIllnessItemsOptions = {
  '0': 'CI / Term 10',
  '1': 'CI / Term 15',
  '2': 'CI / Term 20',
  '3': 'CI / Term 25',
  '4': 'CI / Term 30',
  '5': 'CI / Term 65',
  '6': 'CI / Term 70',
  '7': 'CI / Term 75',
  '8': 'CI / T100 - Life Pay',
  '9': 'CI / T100 - Pay to 65',
  '10': 'CI / T100 - 10-20 Pay',
  '11': 'CI / Cond. limited Pay',
  '12': 'CI / Decreasing',
  '13': 'Comb Life & CI/25%', //(There is no 13,14,15 item in WinQuote documentation, but they are using it in production form)
  '14': 'Comb Life & CI/50%',
  '15': 'Comb Life & CI/75%'
};
export const criticalIllnessItemsList = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15'
];

export const criticalIllnessOptions = {
  '0': 'Heart Attack',
  '1': 'Cancer',
  '2': 'Stroke',
  '3': 'Acquired Brain Injury',
  '4': "Alzheimer's Disease",
  '5': 'Aortic Surgery',
  '6': 'Aplastic Anemia',
  '7': 'Autism',
  '8': 'Bacterial Meningitis',
  '9': 'Benign Brain Tumour',
  '10': 'Blindness',
  '11': 'Cerebral Palsy',
  '12': 'Coma',
  '13': 'Congenital Heart Disease',
  '14': 'Coronary Angioplasty',
  '15': 'Coronary Artery Surgery',
  '16': 'Cystic Fibrosis',
  '17': 'Deafness',
  '18': 'Heart Valve Replacement',
  '19': 'Kidney (Renal) Failure',
  '20': 'Loss of Independence',
  '21': 'Loss of Limbs',
  '22': 'Loss of Speech',
  '23': 'Major/Vital Organ Failure/Transplant',
  '24': "Motor Neuron /Lou Gehrig's Disease",
  '25': 'Multiple Sclerosis',
  '26': 'Muscular Dystrophy',
  '27': 'Occupational HIV',
  '28': 'Paralysis',
  '29': "Parkinson's Disease",
  '30': 'Rett Syndrome',
  '31': 'Severe Burns',
  '32': 'Type 1 Diabetes Mellitus'
};

export const tubaccoGroup = {
  tucgt: 'Cigarette',
  tucgr: 'Cigar',
  tucgl: 'Cigarello',
  tupip: 'Pipe',
  tuchw: 'Chew',
  tumrj: 'Marijuana',
  tuvap: 'Vapor/e-cigarettes',
  tuwat: 'Water & Spiritual Pipes',
  tuoth: 'Other'
};

export const tobaccoOptions = {
  100: 'Never',
  0: 'the last 12 months',
  1: 'the last 1 year',
  2: 'the last 2 years',
  3: 'the last 3 years',
  4: 'the last 4 years',
  5: 'the last 5 years',
  6: 'the last 6 years',
  10: 'the last 10 years',
  15: 'the last 15 years',
  25: 'the last 25 years'
};

export const faceAmountsOptionsPopular = {
  lifeInsurance: [
    {
      value: '100000',
      title: '$ 100,000'
    },
    {
      value: '250000',
      title: '$ 250,000'
    },
    {
      value: '100000',
      title: '$ 100,000'
    },
    {
      value: '500000',
      title: '$ 500,000'
    },
    {
      value: '750000',
      title: '$ 750,000'
    },
    {
      value: '1000000',
      title: '$ 1,000,000'
    }
  ],
  criticalIllness: [
    {
      value: '50000',
      title: '$ 50,000'
    },
    {
      value: '100000',
      title: '$ 100,000'
    }
  ]
};

export const faceAmountOptions = [
  { value: '25000', title: '$ 25,000' },
  { value: '30000', title: ' $ 30,000' },
  { value: '35000', title: ' $ 35,000' },
  { value: '40000', title: ' $ 40,000' },
  { value: '45000', title: ' $ 45,000' },
  { value: '50000', title: ' $ 50,000' },
  { value: '55000', title: ' $ 55,000' },
  { value: '60000', title: ' $ 60,000' },
  { value: '65000', title: ' $ 65,000' },
  { value: '70000', title: ' $ 70,000' },
  { value: '75000', title: ' $ 75,000' },
  { value: '80000', title: ' $ 80,000' },
  { value: '85000', title: ' $ 85,000' },
  { value: '95000', title: ' $ 95,000' },
  { value: '100000', title: ' $ 100,000' },
  { value: '125000', title: ' $ 125,000' },
  { value: '150000', title: ' $ 150,000' },
  { value: '175000', title: ' $ 175,000' },
  { value: '200000', title: ' $ 200,000' },
  { value: '225000', title: ' $ 225,000' },
  { value: '250000', title: ' $ 250,000' },
  { value: '275000', title: ' $ 275,000' },
  { value: '300000', title: ' $ 300,000' },
  { value: '325000', title: ' $ 325,000' },
  { value: '350000', title: ' $ 350,000' },
  { value: '375000', title: ' $ 375,000' },
  { value: '400000', title: ' $ 400,000' },
  { value: '425000', title: ' $ 425,000' },
  { value: '450000', title: ' $ 450,000' },
  { value: '475000', title: ' $ 475,000' },
  { value: '500000', title: ' $ 500,000' },
  { value: '550000', title: ' $ 550,000' },
  { value: '600000', title: ' $ 600,000' },
  { value: '650000', title: ' $ 650,000' },
  { value: '700000', title: ' $ 700,000' },
  { value: '750000', title: ' $ 750,000' },
  { value: '800000', title: ' $ 800,000' },
  { value: '850000', title: ' $ 850,000' },
  { value: '900000', title: ' $ 900,000' },
  { value: '950000', title: ' $ 950,000' },
  { value: '1000000', title: ' $1,000,000' },
  { value: '1250000', title: ' $1,250,000' },
  { value: '1500000', title: ' $1,500,000' },
  { value: '1750000', title: ' $1,750,000' },
  { value: '2000000', title: ' $2,000,000' },
  { value: '2250000', title: ' $2,250,000' },
  { value: '2500000', title: ' $2,500,000' },
  { value: '2750000', title: ' $2,750,000' },
  { value: '3000000', title: ' $3,000,000' },
  { value: '3500000', title: ' $3,500,000' },
  { value: '4000000', title: ' $4,000,000' },
  { value: '4500000', title: ' $4,500,000' },
  { value: '5000000', title: ' $5,000,000' },
  { value: '6000000', title: ' $6,000,000' },
  { value: '7000000', title: ' $7,000,000' },
  { value: '8000000', title: ' $8,000,000' },
  { value: '9000000', title: ' $9,000,000' },
  { value: '10000000', title: ' $10,000,000' },
  { value: '11000000', title: ' $11,000,000' },
  { value: '12000000', title: ' $12,000,000' },
  { value: '13000000', title: ' $13,000,000' },
  { value: '14000000', title: ' $14,000,000' },
  { value: '15000000', title: ' $15,000,000' },
  { value: '16000000', title: ' $16,000,000' },
  { value: '17000000', title: ' $17,000,000' },
  { value: '18000000', title: ' $18,000,000' },
  { value: '19000000', title: ' $19,000,000' },
  { value: '20000000', title: ' $20,000,000' },
  { value: '21000000', title: ' $21,000,000' },
  { value: '22000000', title: ' $22,000,000' },
  { value: '23000000', title: ' $23,000,000' },
  { value: '24000000', title: ' $24,000,000' },
  { value: '25000000', title: ' $25,000,000' },
  { value: '26000000', title: ' $26,000,000' },
  { value: '27000000', title: ' $27,000,000' },
  { value: '28000000', title: ' $28,000,000' },
  { value: '29000000', title: ' $29,000,000' },
  { value: '30000000', title: ' $30,000,000' }
];

export const provinceOptions = {
  0: 'British Columbia',
  1: 'Alberta',
  2: 'Saskatchewan',
  3: 'Northwest Territories',
  4: 'Yukon',
  5: 'Nunavut',
  6: 'Manitoba',
  7: 'Ontario',
  8: 'Quebec',
  9: 'Nova Scotia',
  10: 'New Brunswick',
  11: 'Prince Edward Island',
  12: 'Newfoundland & Labrador',
  13: 'All Provinces'
};

export const paymentModeOptions = {
  0: 'Annual',
  // 1: 'Semi-Annual',
  // 2: 'Quarterly',
  3: 'Monthly'
};

export const riskOptionsCI = {
  R: 'Regular',
  S: 'Simplified',
  G: 'Guaranteed'
};

export const riskOptionsLife = {
  SP: 'Elite',
  P: 'Preferred',
  R: 'Regular',
  S: 'Simplified',
  G: 'Guaranteed'
};
