import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import theme from './theme';
import { createBrowserHistory } from 'history';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LifeCriticalInsuranceQuote } from 'components';
import global from './theme/global';

//define global styles
const useStyles = makeStyles({
  '@global': global
});

export const history = createBrowserHistory();

function AppLifeCriticalIllnessQuotes(props) {
  useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <LifeCriticalInsuranceQuote {...props} />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default AppLifeCriticalIllnessQuotes;
