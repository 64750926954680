import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Summary = ({ details }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  if (details) {
    return (
      <div className={clsx('quote-summary')}>
        <Button
          variant="outlined"
          className={clsx('quote-summary-btn')}
          onClick={toggleOpen}
          endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
          {isOpen ? <>Close Summary</> : <>Plan Summary</>}
        </Button>
        {isOpen && (
          <div
            style={{ textAlign: 'left' }}
            dangerouslySetInnerHTML={{ __html: details }}
          />
        )}
      </div>
    );
  } else {
    return '';
  }
};

export default Summary;
