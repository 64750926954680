import * as yup from 'yup';

const schema = yup.object().shape({
  dob: yup
    .date()
    .required()
    .typeError('Enter a valid date in DD-MM-YYYY format'),
  g: yup.mixed().oneOf(['1', '2']).required(),
  tobacco: yup.mixed().oneOf(['0', '1']).required(),
  lc: yup.string().required(),
  fa: yup.string().matches(/^\d+$/),
  pm: yup.string().matches(/^\d+$/),
  pg: yup.string().matches(/^\d+$/),
  pi: yup.string().matches(/^\d+$/),
  r: yup.string().required()
});

export { schema };
