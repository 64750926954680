import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  textHelp: {
    fontSize: theme.typography.pxToRem(12)
  },
  listHelp: {
    listStyle: 'none',
    padding: '0',
    margin: '0 0 0 4px'
  },
  tableWrap: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-16px',
      marginRight: '-16px'
    }
  },
  table: {
    '& tr': {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }
}));

const SetHtml = ({ item }) => (
  <div dangerouslySetInnerHTML={{ __html: item }} />
);

const TextHelp = ({ classes, children }) => (
  <div className={clsx('text-help', classes.textHelp)}>{children}</div>
);

const PlanDetails = ({ planDetails, planMeta }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        Plan Details
      </Typography>

      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="benefit-summary"
          id="benefit-summary">
          <Typography className={classes.heading}>Benefit Summary</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {planMeta && (
            <div className={classes.tableWrap}>
              <Table
                size="small"
                className={clsx('table', 'table-plan-meta', classes.table)}>
                <TableBody>
                  {planMeta.basic && (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          className={clsx(
                            'table-header',
                            'table-header-basic'
                          )}>
                          <Typography variant="body2">
                            Basic Benefits
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Covid-19</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.covid19Coverage} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ambulance</TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.ambulanceTransportation}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Hospitalization
                          <TextHelp classes={classes}>
                            (Related to Emergency)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.emergencyHospitalization}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Services of a
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>A Physician</li>
                              <li>A Surgeon</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.physicianSurgeon} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Medical Care
                          <TextHelp classes={classes}>
                            (Related to Emergencies)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.emergencyMedicalCare} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Walk-in Clinic Visits</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.walkInClinicVisit} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Follow Up Treatment</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.followUpTreatment} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Coverage For
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Lab Diagnostics</li>
                              <li>X-Ray</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.laboratoryXRay} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Prescriptions
                          <TextHelp classes={classes}>
                            (Related to Emergencies)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.prescriptionMedication}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Dental Pain Relief</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.dentalPainRelief} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Dental Repair
                          <TextHelp classes={classes}>
                            (Related to Emergencies)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.emergencyDentalRepair}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Assistance Center
                          <TextHelp classes={classes}>(24-hour)</TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.emergencyAssistance24Hours}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ambulance Transportation</TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.ambulanceTransportation}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Home Return
                          <TextHelp classes={classes}>
                            (Related to Medical Emergencies)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.emergencyHomeReturnEvacuation}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Repatriation of Remains</TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.repatriationOfRemains}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Expenses for
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Cremation</li>
                              <li>Burial</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.cremationBurial} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Pre-existing Medical Conditions
                          <TextHelp classes={classes}>
                            (Stability required)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={
                              planMeta.basic.stablePreExistingConditionsCoverage
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Expenses for
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Private Duty Nurse</li>
                              <li>Medical Attendant</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.basic.nurseMedicalAttendant}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Medical Appliances
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Rental</li>
                              <li>Purchase</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={
                              planMeta.basic.rentalPurchaseMedicalAppliances
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Side-Trips Benefit
                          <TextHelp classes={classes}>
                            (with in Canada and outside of Canada)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.basic.sideTripBenefits} />
                        </TableCell>
                      </TableRow>
                    </>
                  )}

                  {planMeta.enhanced && (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          className={clsx(
                            'table-header',
                            'table-header-enhanced'
                          )}>
                          <Typography variant="body2">
                            Enhanced Benefits
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Emergency Services
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Chiropractor</li>
                              <li>Chiropodist</li>
                              <li>Physiotherapist</li>
                              <li>Osteopath</li>
                              <li>Podiatrist</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.emergencySpecialistService}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Accidental Death</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.enhanced.accidentalDeath} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Double Dismemberment</TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.doubleDismemberment}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Single Dismemberment</TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.singleDismemberment}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Bedside Companion
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Accommodation</li>
                              <li>Transportation</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={
                              planMeta.enhanced.bedsideCompanionAccommodation
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Hospital Expenses
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Meals</li>
                              <li>Accommodation</li>
                              <li>Accommodation</li>
                              <li>Out-of-pocket</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.mealsHospitalAllowance}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Maternity Benefits
                          <br />
                          Delivery Coverage
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.enhanced.maternityBenefits} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Pregnancy Coverage
                          <TextHelp classes={classes}>
                            (Related to Complications)
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={
                              planMeta.enhanced.pregnancyComplicationCoverage
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Physical Examination
                          <TextHelp classes={classes}>(Non-emergency)</TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.physicalExamination}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Eye Examination
                          <TextHelp classes={classes}>(Non-emergency)</TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.enhanced.eyeExamination} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Vaccines
                          <TextHelp classes={classes}>(Non-emergency)</TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.enhanced.vaccines} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Coverage for
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Child Care Exp</li>
                              <li>Escort Expenses</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.childCareEscortExpenses}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Coverage for
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Psychiatric</li>
                              <li>Psychological</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.psychiatricPsychological}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Return of a Vehicle</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.enhanced.returnOfaVehicle} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sports Injuries</TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.sportsInjuriesCoverage}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Accidents
                          <TextHelp classes={classes}>
                            <ul className={clsx('list-help', classes.listHelp)}>
                              <li>Flight </li>
                              <li>Travel</li>
                            </ul>
                          </TextHelp>
                        </TableCell>
                        <TableCell>
                          <SetHtml
                            item={planMeta.enhanced.flightTravelAccident}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Trip-Break Benefit</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.enhanced.tripBreakBenefit} />
                        </TableCell>
                      </TableRow>
                      {planMeta.enhanced.commonCarrier && (
                        <>
                          <TableRow>
                            <TableCell>Common Carrier</TableCell>
                            <TableCell>
                              <SetHtml item={planMeta.enhanced.commonCarrier} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Corrective Devices</TableCell>
                            <TableCell>
                              <SetHtml
                                item={planMeta.enhanced.correctiveDevices}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Prescription Glasses / Contact lenses / Hearing
                              Aids
                            </TableCell>
                            <TableCell>
                              <SetHtml
                                item={
                                  planMeta.enhanced
                                    .prescriptionGlassesLensesHearingAids
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Sexual Health Consultation</TableCell>
                            <TableCell>
                              <SetHtml
                                item={
                                  planMeta.enhanced.sexualHealthConsultation
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Terrorism Coverage( Only for Outbound Insured)
                            </TableCell>
                            <TableCell>
                              <SetHtml
                                item={planMeta.enhanced.terrorismCoverage}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Trauma Counselling</TableCell>
                            <TableCell>
                              <SetHtml
                                item={planMeta.enhanced.traumaCounselling}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Tuberculosis Testing and Vaccination
                            </TableCell>
                            <TableCell>
                              <SetHtml
                                item={
                                  planMeta.enhanced
                                    .tuberculosisTestingVaccination
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Tuition Reimbursement in Relation to Emergency
                            </TableCell>
                            <TableCell>
                              <SetHtml
                                item={planMeta.enhanced.tuitionReimbursement}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Tutorial Services</TableCell>
                            <TableCell>
                              <SetHtml
                                item={planMeta.enhanced.tutorialServices}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Wisdom Teeth Removal</TableCell>
                            <TableCell>
                              <SetHtml
                                item={planMeta.enhanced.wisdomTeethRemoval}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell>Underwritten By</TableCell>
                        <TableCell>
                          <SetHtml item={planMeta.underwrittenBy} />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="requirements"
          id="requirements">
          <Typography className={classes.heading}>
            Eligibility Requirements
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SetHtml item={planDetails.requirements} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="claim"
          id="claim">
          <Typography className={classes.heading}>Make a Claim</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SetHtml item={planDetails.claim} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="refund"
          id="refund">
          <Typography className={classes.heading}>Obtain Refund</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SetHtml item={planDetails.refund} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="exclusions"
          id="exclusions">
          <Typography className={classes.heading}>Exclusions</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SetHtml item={planDetails.exclusions} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default PlanDetails;
