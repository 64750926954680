import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Grid,
  colors
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Yup from 'schemas/Yup';
import * as quoteRequestApi from '../../api/quoteRequestApi';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  popup: {
    border: 'solid 1px #ccc',
    position: 'relative'
  },
  popupClose: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  toggleBtn: {
    display: 'none',

    '&.active': {
      display: 'inline-block'
    }
  },
  formRoot: {
    padding: theme.spacing(6, 3),
    display: 'none',

    '&.active': {
      display: 'block'
    }
  },
  loading: {
    color: theme.palette.white,
    marginLeft: theme.spacing(1)
  },
  msg: {
    textAlign: 'center',
    padding: theme.spacing(3),
    fontSize: '1.3rem',
    color: colors.green[500],
    display: 'none',

    '&.active': {
      display: 'block'
    }
  }
}));

const validationSchema = Yup.object().shape(
  {
    name: Yup.string().optional(),
    email: Yup.string().email().ensure().when('phone', {
      is: '',
      then: Yup.string().email().required()
    }),
    phone: Yup.string()
      .ensure()
      .when('email', {
        is: '',
        then: Yup.string()
          .matches(/^(\d+){10}$/, {
            excludeEmptyString: true,
            message: 'enter a valid 10 digit number without spaces'
          })
          .max(10)
          .optional(),
        otherwise: Yup.string()
          .matches(/^(\d+){10}$/, {
            excludeEmptyString: true,
            message: 'enter a valid 10 digit number without spaces'
          })
          .max(10)
          .optional()
      })
  },
  [['email', 'phone']]
);

const SendQuote = ({ onSendQuote, title, client }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [msg, setMsg] = useState(null);

  return (
    <div className={clsx('send-quote', classes.root)}>
      <Button
        className={clsx(
          'send-quote-send-btn',
          classes.toggleBtn,
          step === 1 && 'active'
        )}
        color="primary"
        disableElevation
        onClick={() => {
          setStep(2);
        }}
        size="large"
        type="submit"
        variant="contained">
        {title ? title : 'Email/Text these rates'}
      </Button>
      {step > 1 && (
        <div className={classes.popup}>
          <IconButton
            aria-label="close"
            classes={{
              root: classes.popupClose
            }}
            onClick={() => {
              setStep(1);
            }}>
            <CancelIcon />
          </IconButton>

          <div className={clsx(classes.msg, step === 3 && 'active')}>{msg}</div>

          <div className={clsx(classes.formRoot, step === 2 && 'active')}>
            <Typography className={classes.title} variant="h4">
              Get an Email/Text
            </Typography>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                url: window.location.href
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                //console.log(values);
                onSendQuote({ ...values, clientId: client._id })
                  .then((result) => {
                    setSubmitting(false);

                    if (result.result > 0) {
                      setStep(3);
                      setMsg('Quote successfully emailed/texted');

                      // quoteRequest is logged on server;
                      // because we also want to log shortUrl, which is generated on server;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(
                      'something went wrong, please try again or contact us'
                    );
                    setSubmitting(false);
                  });
              }}>
              {({ handleChange, values, isSubmitting, errors }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        fullWidth
                        label="Your name"
                        name="name"
                        InputProps={{
                          onChange: handleChange
                        }}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        fullWidth
                        label="Email"
                        name="email"
                        InputProps={{
                          onChange: handleChange
                        }}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        fullWidth
                        label="Phone"
                        name="phone"
                        InputProps={{
                          onChange: handleChange
                        }}
                        value={values.phone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="mr-1">
                        <Button
                          color="primary"
                          disableElevation
                          size="large"
                          type="submit"
                          variant="contained">
                          {isSubmitting ? (
                            <>
                              submitting{' '}
                              <CircularProgress
                                classes={{ root: classes.loading }}
                                color="secondary"
                                size={15}
                              />
                            </>
                          ) : (
                            'Email/Text'
                          )}
                        </Button>
                      </span>
                      {values.phone && !errors.phone && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            const url = `https://wa.me/1${
                              values.phone
                            }?text=Dear ${
                              values.name ? values.name : 'Customer'
                            },%0AI appreciate your request for a quote. You can review your quote by clicking on the link below.%0A%0A${encodeURIComponent(
                              window.location.href
                            )}`;
                            // log the quoteRequest;
                            quoteRequestApi.post({
                              clientId: client._id,
                              name: values.name,
                              phone: values.phone,
                              email: values.email,
                              url: window.location.href
                            });

                            window.open(url);
                          }}>
                          Whatsapp
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendQuote;
