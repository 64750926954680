import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { studentSchema } from 'schemas/quotes';
import FormikErrorFocus from 'formik-error-focus';
import { Form as QuoteForm } from './components';
import { Typography, makeStyles } from '@material-ui/core';
import { toQueryString, dateToDb, dbToDate } from 'common/Helper';
import queryString from 'query-string';
import { getPlanBySlug } from 'api/planApi';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(5)
  }
}));

const scrollToContentTop = (ref) => {
  if (window) {
    window.scrollTo(0, ref.current.offsetTop);
  }
};

const Student = ({ location, history }) => {
  const classes = useStyles();
  const mainContent = useRef(null);
  const [query, setQuery] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    scrollToContentTop(mainContent);
    setQuery(queryString.parse(location.search));

    if (slug) {
      getPlanBySlug(slug)
        .then((plan) => {
          setQuery({ ...query, plan: plan._id });
        })
        .catch(console.log);
    }
  }, [slug]);

  return (
    <div ref={mainContent}>
      <Typography className={classes.title} variant="h3" component="h2">
        Enter student's details.
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          coverageType: query.coverageType ? query.coverageType : 'single',
          age1: query.age1 ? query.age1 : 20,
          age2: query.age2 ? query.age2 : 20,
          startDate1:
            query.startDate1 && dbToDate(query.startDate1) !== ''
              ? dbToDate(query.startDate1)
              : moment().format(process.env.REACT_APP_DATE_FORMAT),
          endDate1:
            query.endDate1 && dbToDate(query.endDate1) !== ''
              ? dbToDate(query.endDate1)
              : moment()
                  .add(89, 'days')
                  .format(process.env.REACT_APP_DATE_FORMAT),
          days1:
            query.startDate1 &&
            query.endDate1 &&
            dbToDate(query.endDate1) !== '' &&
            dbToDate(query.endDate1) !== ''
              ? moment(
                  dbToDate(query.endDate1),
                  process.env.REACT_APP_DATE_FORMAT
                ).diff(
                  moment(
                    dbToDate(query.startDate1),
                    process.env.REACT_APP_DATE_FORMAT
                  ),
                  'days'
                ) + 1
              : 90,
          dependents: query.dependents ? query.dependents : 2
        }}
        validationSchema={studentSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          const valuesSelected = {
            coverageType: values.coverageType,
            startDate1: dateToDb(values.startDate1),
            endDate1: dateToDb(values.endDate1),
            days1: values.days1,
            age1: values.age1
          };

          if (
            values.coverageType === 'couple' ||
            values.coverageType === 'family'
          ) {
            valuesSelected.age2 = values.age2;
          }

          if (values.coverageType === 'family') {
            valuesSelected.dependents = values.dependents;
          }

          history.push(
            `/student-insurance/quotes?${toQueryString({
              ...valuesSelected
            })}`
          );
        }}>
        {(props) => (
          <Form>
            <QuoteForm {...props} />
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={0}
              align={'top'}
              focusDelay={200}
              ease={'linear'}
              duration={500}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Student;
